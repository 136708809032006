import './index.css';
import { Routes, Route, Link } from 'react-router-dom';
import Zonelist from './app/Zonelist';
import Header from "./components/Header";
import Authtkt from './app/Authtkt';
import Add from "./components/page/Add";
import ZoneLoader from "./app/ZoneLoader";
import Delete from './components/page/Delete';
import Edit from "./components/page/Edit";
import AuditLoader from './app/AuditLoader';
import Log from './components/page/Log';
import React from "react";

function NoMatch(props) {
  return (
    <div>
      <p>Not found</p>
      <Link to={props.homeurl}>Home</Link>
    </div>
  );
}

function App(props) {
  const deletePage = <ZoneLoader>
    {(zoneAndRecords) => <Delete zoneAndRecords={zoneAndRecords} />}
  </ZoneLoader>;
  const editPage = <ZoneLoader>
    {(zoneAndRecords) => <Edit zoneAndRecords={zoneAndRecords} />}
  </ZoneLoader>;
  const logPage = <AuditLoader>
    {(zoneAndRecords, audits, getMoreAudits) => <Log zoneAndRecords={zoneAndRecords}
                                                     startingAudits={audits}
                                                     getMoreAudits={getMoreAudits} />}
  </AuditLoader>


  return (
    <div className="App">
      <Header logo="HEADER_RACKSPACE" />
      <Routes>
        <Route path={props.homeurl} element={<Zonelist homeurl={props.homeurl} />} />
        <Route path={`${props.homeurl}/vdns`} element={<Zonelist homeurl={props.homeurl} />} />
        <Route path={`${props.homeurl}/Archive`} element={<Zonelist homeurl={props.homeurl} />} />
        <Route path={`${props.homeurl}/Archive/list.html`} element={<Zonelist homeurl={props.homeurl} />} />
        <Route path={`${props.homeurl}/vdns/index.html`} element={<Zonelist homeurl={props.homeurl} />} />
        <Route path={`${props.homeurl}employee/authtkt.html`} element={<Authtkt homeurl={props.homeurl} />} />
        <Route path={`${props.homeurl}vdns/add.html`} element={<Add />} />
        <Route path={`${props.homeurl}Archive/add.html`} element={<Add />} />
        <Route path={`${props.homeurl}vdns/edit.html`} element={editPage} />
        <Route path={`${props.homeurl}Archive/edit.html`} element={editPage} />
        <Route path={`${props.homeurl}vdns/delete.html`} element={deletePage} />
        <Route path={`${props.homeurl}Archive/delete.html`} element={deletePage} />
        <Route path={`${props.homeurl}vdns/log.html`} element={logPage} />
        <Route path={`${props.homeurl}Archive/log.html`} element={logPage} />
        <Route path="*" element={<NoMatch homeurl={props.homeurl} />} />
      </Routes>
    </div>
  );
}

export default App;
