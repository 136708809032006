import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const midDot = `\xB7` // middle dot character
const space = `\xa0` // no-break space

export default function TablePagination({count, queryOwner, querySearch, queryCustomerId, queryOffset, queryRowcount}) {
  const totalPages = count/queryRowcount < 1 ? 1 : Math.ceil(count/queryRowcount) // totalPages can't be decimal; rounded up
  const currentPage = queryOffset === 0 ? 1 : queryOffset/queryRowcount + 1
  let text = ""
  if (currentPage === 1) {
    text = `Page 1 of ${totalPages} ${space} ${midDot}${space} << First ${space} < Back ${space}${midDot} ` +
      `${space}${space} `
  } else if (currentPage === totalPages || currentPage > 1) {
    text = `Page ${currentPage} of ${totalPages} ${space} ${midDot} ${space}`
  }
  return (
    <tr>
      <td style={{ textAlign: 'right', columnSpan: "all"}}>
        {text}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          queryOwner={queryOwner}
          querySearch={querySearch}
          queryCustomerId={queryCustomerId}
          queryOffset={queryOffset}
          queryRowcount={queryRowcount}/>
      </td>
    </tr>
  )
}

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  queryOwner: PropTypes.string,
  querySearch: PropTypes.string,
  queryCustomerId: PropTypes.string,
  queryOffset: PropTypes.number,
  queryRowcount: PropTypes.number
};

TablePagination.defaultProps = {
};

function Pagination ({currentPage, totalPages, queryOwner, querySearch, queryCustomerId, queryOffset, queryRowcount}) {
  const pageLinks = []
  const toBase = `/vdns/index.html?owner_name=${queryOwner}&search=${querySearch}&customerid=${queryCustomerId}`
  let toPage = ``
  let newOffset = 0
  const lastOffset = (totalPages - 1) * queryRowcount
  const nextOffset = queryOffset + queryRowcount
  let previousPages = currentPage - 1
  const nextPages = totalPages - currentPage

  if (currentPage > 1 && currentPage <= totalPages) {
    toPage = `&offset=0&rowcount=${queryRowcount}`
    pageLinks.push(<Link to={`${toBase}${toPage}`} key='first'>{`<< First ${space} `}</Link>)
    newOffset = queryOffset - queryRowcount < 1 ? 0 : queryOffset - queryRowcount
    toPage = `&offset=${newOffset}&rowcount=${queryRowcount}`
    pageLinks.push(<Link to={`${toBase}${toPage}`} key='back'>{`< Back ${space}`}</Link>, `${midDot} ${space} `)
  }

  // limit previous pages to 5 page links
  if (previousPages > 5) {
    pageLinks.push(`... ${space} `)
    previousPages--
  }

  // generate previous page links
  let loopLimit = previousPages - 5 < 1 ? 0 : previousPages - 5
  for (let i = previousPages; i > loopLimit; i--) {
    newOffset = queryOffset - (queryRowcount*i) < 1 ? 0 : queryOffset - (queryRowcount*i)
    toPage = `&offset=${newOffset}&rowcount=${queryRowcount}`
    pageLinks.push(<Link to={`${toBase}${toPage}`} key={currentPage - i}>{currentPage - i} {space} </Link>)
  }

  // reach current page
  pageLinks.push(`${currentPage} ${space}`)

  // generate next page links
  for (let i = 1; i <= nextPages; i++) {
    if (i > 5) {
      pageLinks.push(`... ${space}${midDot} ${space}`)
      toPage = `&offset=${nextOffset}&rowcount=${queryRowcount}`
      pageLinks.push(<Link to={`${toBase}${toPage}`} key='next'>Next > {space} </Link>)
      toPage = `&offset=${lastOffset}&rowcount=${queryRowcount}`
      pageLinks.push(<Link to={`${toBase}${toPage}`} key='last'>Last >></Link>)
      break;
    }
    newOffset = queryOffset + (queryRowcount*i)
    toPage = `&offset=${newOffset}&rowcount=${queryRowcount}`
    pageLinks.push(<Link to={`${toBase}${toPage}`} key={currentPage + i}> {currentPage + i} {space}</Link>)

    if (i === nextPages) {
      pageLinks.push(`${midDot} ${space}`)
      toPage = `&offset=${nextOffset}&rowcount=${queryRowcount}`
      pageLinks.push(<Link to={`${toBase}${toPage}`} key='next'>Next > {space} </Link>)
      toPage = `&offset=${lastOffset}&rowcount=${queryRowcount}`
      pageLinks.push(<Link to={`${toBase}${toPage}`} key='last'>Last >></Link>)
    }
  }

  if (currentPage === totalPages) {
    pageLinks.push(`${midDot}${space} Next > ${space} Last >>`)
  }

  return pageLinks
}
