import PropTypes from 'prop-types';

function formatChanges(data, compareData, auditLine) {
  const lines = [];
  for (const key in data) {
    if (data[key] !== compareData[key]) {
      if (data[key] !== null) {
        lines.push(`${key} = ${data[key]}`);
      }
    }
  }

  return lines.map((line, i) => <span key={`${auditLine}-${i}`}>{line}<br /></span>);
}

export default function Audit({i, audit}) {
  const auditBefore = JSON.parse(audit.before);
  const auditAfter = JSON.parse(audit.after);

  return (
     <tr className={(i % 2) ? "Row" : "AlternateRow"}>
        <td key={`${audit.auditId}-createdOn`}>{audit.createdOn}</td>
        <td key={`${audit.auditId}-action`}>{audit.action}</td>
        <td key={`${audit.auditId}-fqdn`}>{audit.fqdn}</td>
        <td key={`${audit.auditId}-createdBy`}>{audit.createdBy}</td>
        <td key={`${audit.auditId}-before`}>{formatChanges(auditBefore, auditAfter, i)}</td>
        <td key={`${audit.auditId}-after`}>{formatChanges(auditAfter, auditBefore, i)}</td>
     </tr>
  );
}

Audit.propTypes ={
  i: PropTypes.number.isRequired,
  audit: PropTypes.object.isRequired,
};

Audit.defaultProps = {
};
