import NavHeader from "../NavHeader";
import NewDomainForm from "../NewDomainForm";
import Banner from "../Banner";

export default function Add() {
  return (
    <div>
      <NavHeader currentPage="/vdns/add.html"></NavHeader>
      <Banner>Add a Domain</Banner>
      <div className="mainbody">
        <NewDomainForm></NewDomainForm>
      </div>
    </div>
  );
}
