import EditRecordsForm from "../EditRecordsForm";
import PropTypes from 'prop-types';
import AddRecordForm from "../AddRecordForm";
import EditZoneForm from "../EditZoneForm";
import NameserverList from "../NameserverList";
import NavHeader from "../NavHeader";
import Banner from "../Banner";
import React, {useState} from "react";
import {AddMessage} from "../Messages";

export default function Edit({zoneAndRecords, messageState}) {
  const [message, setMessage] = useState(messageState);

  return (
    <div>
      <NavHeader zoneAndRecords={zoneAndRecords} currentPage="/vdns/edit.html"/>
      <Banner>Edit Domain {zoneAndRecords.zone.fqdn}</Banner>
      <div className="mainbody">
        <AddMessage message={message}/>
        <table width="100%">
          <tbody><tr>
            <td width="50%" valign="top">
              <EditRecordsForm zoneAndRecords={zoneAndRecords} setMessage={setMessage}/>
              <hr />
              <AddRecordForm zone={zoneAndRecords.zone} setMessage={setMessage}/>
            </td>
            <td width="50%" valign="top">
              <EditZoneForm zone={zoneAndRecords.zone} setMessage={setMessage}/>
              <br/>
              <NameserverList />
            </td>
          </tr></tbody>
        </table>
      </div>
    </div>
  );
}

Edit.propTypes = {
  zoneAndRecords: PropTypes.object.isRequired,
};

Edit.defaultProps = {
};
