import React from "react";
import AuditList from "../AuditList";
import PropTypes from 'prop-types';
import NavHeader from "../NavHeader";
import Banner from "../Banner";

export default function Log({startingAudits, getMoreAudits, zoneAndRecords}) {
  return (
    <div>
      <NavHeader zoneAndRecords={zoneAndRecords} currentPage="/vdns/log.html"/>
      <Banner>Logs for {zoneAndRecords.zone.fqdn}</Banner>
      <div className="mainbody">
        <AuditList startingAudits={startingAudits} getMoreAudits={getMoreAudits} />
      </div>
    </div>
  );
}

Log.propTypes = {
  zoneAndRecords: PropTypes.object.isRequired,
  startingAudits: PropTypes.object.isRequired,
  getMoreAudits: PropTypes.func.isRequired,
};

Log.defaultProps = {
};
