export default function TTL({value, onChange}) {
  const ttls = [30,60,300,600,3600,7200];
  return (<select value={value} onChange={onChange}>
    <option value="">Default</option>
    {ttls.map((ttl) => {
      return (
        <option value={ttl} key={ttl}>{ttl}s</option>
      );
    })}
  </select>);
}

