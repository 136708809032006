import PropTypes from "prop-types";

export default function TableRows({data, alternate}) {
  if (alternate) {
    return (
      <AlternatingRows data={data}/>
    )
  }

  return (
    data.map((rowObject, i) => {
      return (
        <tr className="Row">
          <TableCells rowObject={rowObject} key={i}/>
        </tr>
      )
    })
  )
}

TableRows.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  alternate: PropTypes.bool
}

TableRows.defaultProps = {
  data: [],
  alternate: true
}

function AlternatingRows({data}) {
  return (
    data.map((rowObject, i) => {
      return (
        <tr className={i%2 ? "AlternateRow" : "Row"} key={i}>
          <TableCells rowObject={rowObject}/>
        </tr>
      )
    })
  )
}

function TableCells({rowObject}) {
  const cells = []
  for (const [key, cellValue] of Object.entries(rowObject)) {
    cells.push(<td key={key}>{cellValue}</td>)
  }
  return cells
}
