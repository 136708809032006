import PropTypes from 'prop-types';
import Banner from "../Banner";
import {DomainsTable} from "../Table/DomainsTable";
import NameserverList from "../NameserverList";
import NavHeader from "../NavHeader";
import Messages from "../Messages";

export default function Index({zonesList, formOwner, setOwner, queryOwner, formSearch, setSearch, querySearch,
                                formCustomerId, setCustomerId, queryCustomerId, queryOffset, formRowcount, setRowcount,
                                queryRowcount, homeurl, message, handleSubmit}) {

  function handleChange(setState) {
    return (event) => {
      setState(event.target.value);
    };
  }

  return (
    <main>
      <NavHeader currentPage="/vdns/index.html"/>
      <Banner>Virtual DNS</Banner>
      <div className="mainbody">
        {message ? <Messages message={message}/> : ""}
        <table width={"100%"}>
          <tr>
            <td width="50%" valign="top">
              <DomainsTable zones={zonesList.zones}
                            count={zonesList.count}
                            queryOwner={queryOwner}
                            querySearch={querySearch}
                            queryCustomerId={queryCustomerId}
                            queryOffset={queryOffset}
                            queryRowcount={queryRowcount}
                            homeurl={homeurl}/>
            </td>
            <td width="50%" valign="top">
              <NameserverList/>
            </td>
          </tr>
        </table>
        <form  onSubmit={handleSubmit} action={`${homeurl}vdns/index.html`} style={{fontSize: "8pt"}}>
          Domain name:
          <input name="search" value={formSearch} onChange={handleChange(setSearch)}/><br/>
          Find all domains for owner:
          <input name="owner_name" value={formOwner} onChange={handleChange(setOwner)}/><br/>
          Rows per page:
          <input name="rowcount" value={formRowcount} onChange={handleChange(setRowcount)}/><br/>
          Customer id:
          <input name="customerid" value={formCustomerId} onChange={handleChange(setCustomerId)}/><br/>
          <input type="submit" value="Search"/>
        </form>
      </div>
    </main>
  )
}

Index.propTypes = {
  zonesList: PropTypes.object,
  formOwner: PropTypes.string,
  queryOwner: PropTypes.string,
  formSearch: PropTypes.string,
  querySearch: PropTypes.string,
  formCustomerId: PropTypes.string,
  queryCustomerId: PropTypes.string,
  queryOffset: PropTypes.number,
  formRowcount: PropTypes.number,
  queryFormRowcount: PropTypes.number,
  homeurl: PropTypes.string,
  messages: PropTypes.object
};

Index.defaultProps = {
};

