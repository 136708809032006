import React, { useState } from "react";
import Messages from "./Messages";

export default function NewDomainForm({zoneState}) {
  const defaultSubmitButton = <input type="submit" value="Add Domain" />;
  const [DomainName, setDomainName] = useState("");
  const [CustomerID, setCustomerID] = useState("");
  const [newZone, setNewZone] = useState(zoneState);
  const [submitButton, setSubmitButton] = useState(defaultSubmitButton);

  function handleChange(setState) {
    return (event) => {
      setState(event.target.value);
    };
  }

  async function createZone() {
    try {
      const zoneData = {
        DomainName,
        CustomerID,
      };
      const results = await fetch(`${window.location.origin}/api/zone`, {
        headers: {
          "X-APIKEY": localStorage.SessionToken,
        },
        method: "POST",
        body: JSON.stringify(zoneData),
      });
      const resp = await results.json();
      if (!results.ok) {
        setNewZone(resp.message);
        return;
      }
      setNewZone(resp);
    } catch(e) {
      console.error(e);
      setNewZone("Unknown error occurred");
    } finally {
      setSubmitButton(defaultSubmitButton);
    }
  }

  function handleSubmit(event) {
    setSubmitButton("Creating...");
    createZone();
    event.preventDefault();
  }

  function AddedDomain({newZone}) {
    if (typeof(newZone) === "string") {
      return (<Messages message={newZone} />);
    }
    if (newZone !== undefined) {
      return (<Messages message={`${newZone.fqdn} Created, `} link={`/vdns/edit.html?id=${newZone.id}`} linktext="Edit" />);
    }

    return null;
  }

  return (
    <div>
      <AddedDomain newZone={newZone} />
      <table className="SectionTable" width="100%">
        <tbody>
          <tr>
            <td className="SectionHeader" align="left">New Domain Form</td>
          </tr>
          <tr>
            <td className="SectionBody">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <th align="right">Domain Name:</th>
                      <td><input name="DomainName" value={DomainName} onChange={handleChange(setDomainName)} /></td>
                    </tr>
                    <tr>
                      <th align="right">Customer ID:</th>
                      <td><input name="CustomerID" className="textBox" value={CustomerID} onChange={handleChange(setCustomerID)} /></td>
                    </tr>
                    <tr>
                      <th colSpan="2" align="right">{submitButton}</th>
                    </tr>
                  </tbody>
                </table>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

NewDomainForm.propTypes = { };

NewDomainForm.defaultProps = { };
