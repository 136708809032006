import React, { useEffect, useState } from "react";

export default function Saml(props) {
  const [loginMessage, setLoginMessage] = useState("Preparing to login...");

  useEffect(() => {
      async function fetchLogin() {
          try {
            const url = new URL(`${window.location.origin}${props.homeurl}saml`);
            url.searchParams.set("relayState", window.location.href);
            const results = await fetch(url.toString());
            const loginUrl = await results.json();
            window.location.replace(loginUrl.url);
          } catch(e) {
            console.error(e);
            setLoginMessage("internal error when logging in - try reloading");
          }
      }

      fetchLogin();
  }, [props.homeurl]);

  return (
      <>
          <main>
              <div id="layout-content" className="layout-content-wrapper">
                  {loginMessage}
              </div>
          </main>
      </>
  );
}
