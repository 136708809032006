export default function NameserverList() {
  return (
    <table className="SectionTable" width="100%">
      <tbody><tr>
        <td className="SectionHeader" align="left">
          Nameservers
        </td>
      </tr>
      <tr>
        <td className="SectionBody">
          <table width="100%">
            <tbody><tr className="HeaderRow">
              <td>Host Name</td>
            </tr>
            <tr className="Row">
              <td>ns.rackspace.com</td>
            </tr>
            <tr className="AlternateRow">
              <td>ns2.rackspace.com</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr></tbody>
    </table>
  );
}
