import {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import Index from "../components/page/Index";

export default function ZoneList(props) {
    const [zones, setZones] = useState("Loading...");
    const [message, setMessage] = useState(null);
    const [query, setSearchParams] = useSearchParams();
    const [formOwner, setOwner] = useState(query.get("owner_name") || "");
    const queryOwner = useRef(formOwner);
    const [formSearch, setSearch] = useState(query.get("search") || "");
    const querySearch = useRef(formSearch);
    const [formCustomerId, setCustomerId] = useState(query.get("customerid") || "");
    const queryCustomerId = useRef(formCustomerId);
    const [formRowcount, setRowcount] = useState(query.get("rowcount") || "20");
    const queryRowcount = useRef(formRowcount);
    const queryOffset = query.get("offset") || "0";

    function handleSubmit(event) {
        setSearchParams({"owner_name": formOwner, "search": formSearch, "customerid": formCustomerId,
            "offset": "0", "rowcount": formRowcount})
        queryOwner.current = formOwner;
        querySearch.current = formSearch;
        queryCustomerId.current = formCustomerId;
        queryRowcount.current = formRowcount;
        event.preventDefault();
    }

    useEffect(() => {
        const urlQuery = generateUrlQuery(query.get("owner_name"), query.get("search"), query.get("customerid"),
          query.get("offset"), query.get("rowcount"), setMessage);

        async function fetchZones() {
            try {
                const results = await fetch(`${window.location.origin}${props.homeurl}api/zone${urlQuery}`, {
                    headers: {
                        "X-APIKEY": localStorage.SessionToken,
                    }
                });
                const zoneslist = await results.json();
                if (!results.ok) {
                    console.error(zoneslist);
                    console.error(results);
                    setZones(zoneslist.message);
                    return;
                }
                setZones(zoneslist);
            } catch(e) {
                console.error(e);
                setZones("failed");
            }
        }

        fetchZones();
    }, [props.homeurl, query]);

    if (typeof(zones) !== "object" || !Array.isArray(zones.zones)) {
        return zones;
    }
    return <Index zonesList={zones}
                  formOwner={formOwner} setOwner={setOwner} queryOwner={queryOwner.current}
                  formSearch={formSearch} setSearch={setSearch} querySearch={querySearch.current}
                  formCustomerId={formCustomerId} setCustomerId={setCustomerId} queryCustomerId={queryCustomerId.current}
                  queryOffset={parseInt(queryOffset)}
                  formRowcount={formRowcount} setRowcount={setRowcount} queryRowcount={parseInt(queryRowcount.current)}
                  homeurl={props.homeurl}
                  message={message}
                  handleSubmit={handleSubmit}/>;
}

function generateUrlQuery(owner, search, customerId, offset, rowcount, setMessage) {
    const query = new URLSearchParams("")
    let validations = ""
    if (owner) {
        query.append("owner_name", owner)
    }
    if (search) {
        query.append("search", search);
    }
    if (customerId) {
        query.append("customerid", customerId);
    }
    if (offset && rowcount) {
        validations = validateOffsetAndRowcount({offset, rowcount})
        if (validations && validations !== "") {
            setMessage(validations)
        }
       query.append("offset", offset);
       query.append("rowcount", rowcount);
    } else if (offset) {
        validations = validateOffsetAndRowcount({offset, rowcount: "20"})
        if (validations && validations !== "") {
            setMessage(validations)
        }
        query.append("offset", offset);
    } else if (rowcount) {
        validations = validateOffsetAndRowcount({offset: "0", rowcount})
        if (validations && validations !== "") {
            setMessage(validations)
        }
        query.append("rowcount", rowcount);
    }
    return "?" + query.toString();
}

function validateOffsetAndRowcount({offset, rowcount}) {
    const errors = ``
    if (!parseInt(offset)) {
        errors.concat(`offset ${offset} is not a number. `)
    } else {
        offset = parseInt(offset)
    }
    if (!parseInt(rowcount)) {
        errors.concat(`rowcount ${rowcount} is not a number. `)
    } else {
        rowcount = parseInt(rowcount)
    }
    if (errors !== "") {
        return errors
    }

    if (offset < 0) {
        errors.concat(`offset can not be a negative number. `)
    } else if (offset % rowcount !== 0) {
        errors.concat(`offset must be a multiple of row count. `)
    }

    if (rowcount <= 0) {
        errors.concat(`row count must be greater than 0. `)
    }
    return errors;
}
