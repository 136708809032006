import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function OptionalLink({ link, linktext }) {
  if (link === undefined || link === "") {
    return null;
  }
  return <Link to={link}>{linktext}</Link>;
}

export function AddMessage({message}) {
  if (typeof(message) === "string") {
    return (<Messages message={message}/>);
  }
  return null;
}

export default function Messages({ message, link, linktext }) {
  return (
    <table className="SectionTable" width="100%">
      <tr>
        <td className="SectionHeader" align="left">Messages</td>
      </tr>
      <tr>
        <td className="SectionBody">
          { message }
          <OptionalLink link={link} linktext={linktext} />
        </td>
      </tr>
    </table>
  );
}


Messages.propTypes ={
  message: PropTypes.string.isRequired,
  link: PropTypes.string,
  linktext: PropTypes.string,
}

Messages.defaultProps = {
  message: ""
}
