import React from "react";
import PropTypes from 'prop-types';
import TTL from "./TTL";

function recordValue(record) {
  if (record.type === "TXT") {
    return record.text;
  }
  if (record.type === "SRV") {
    return `${record.priority} ${record.weight} ${record.port} ${record.destination}`;
  }
  return record.destination;
}

function recordHostname(record, zone) {
  const fqdnRegex = new RegExp(`(\\.|^)${zone.fqdn}$`);
  const fqdn = record.fqdn.replace(fqdnRegex,"");
  if (record.type === "SRV") {
    return `${record.service}.${record.protocol}${fqdn === "" ? "" : "."}${fqdn}`;
  }
  return fqdn;
}

export default function RecordForm({record, zone, setRecord, i, originalRecords, setMessage}) {
  function handleChange(field) {
    return (event) => {
      const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
      // skip any changes when edit and delete conflict occurs and setMessage to inform user
      if ((record.delete === true && typeof value !== "boolean") || (record.changed === true && value === true)) {
        setMessage("Error: Edit and Delete conflict. " +
        "Either edit record or mark record for deletion, but not both. Refresh page to reset");
        return;
      }
      if (record.type === "PTR" && field === "hostname") {
        setMessage("Error: Not allowed to change Hostname for PTR. " +
        "Create new record(s) and delete any obsolete/unnecessary records");
        return
      }
      setRecord({
        ...record,
        // value of true means delete checkbox was checked
        changed: value === true ? false :
          // this checks whether or not actual record values have changed from the original values
          (originalRecords[i][field] !== (isNaN(Number(value)) ? value : Number(value)) ),
        [field]: value,
      });
    };
  }

  record.hostname = record.hostname || recordHostname(record, zone);
  record.value = record.value ?? recordValue(record);
  record.delete = record.delete || false;
  const priorityInput = <input value={record.priority} className="textBox" onChange={handleChange("priority")} />;
  return (
    <tr className={ i%2 ? "AlternateRow" : "Row"}>
      <td nowrap="">
        <input value={record.hostname} className="textBoxFitted" onChange={handleChange("hostname")} />{zone.fqdn}
      </td>
      <td>{record.type}</td>
      <td>{record.type === "MX" ? priorityInput : ""}</td>
      <td><input value={record.value} className="textBox" onChange={handleChange("value")} /></td>
      <td><TTL value={record.ttl} onChange={handleChange("ttl")}/>
      </td>
      <td><input checked={record.delete} type="checkbox" onChange={handleChange("delete")} /></td>
    </tr>
  );
}

RecordForm.propTypes = {
  record: PropTypes.object.isRequired,
  zone: PropTypes.object.isRequired,
  setRecord: PropTypes.func.isRequired,
  i: PropTypes.number,
};

RecordForm.defaultProps = { };
