import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

export default function AuditLoader({children}) {
  const [zone, setZone] = useState("Loading...");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const zoneId = query.get("id");

  useEffect(() => {

    async function getMoreAudits(key = "") {
      const auditsResp = await fetch(`${window.location.origin}/api/zone/${zoneId}/audits?startingKey=${key}`, {
        headers: {
          "X-APIKEY": localStorage.SessionToken,
        }
      });
      const audits = await auditsResp.json();
      if (!auditsResp.ok) {
        return {
          statusCode: auditsResp.status,
          message: audits.message,
        };
      }

      return {
        statusCode: 200,
        audits,
      };
    }

    async function fetchZone(zoneId) {
      try {
        const zonePromise = fetch(`${window.location.origin}/api/zone/${zoneId}`, {
          headers: {
            "X-APIKEY": localStorage.SessionToken,
          }
        });
        const audits = await getMoreAudits();
        const zoneResp = await zonePromise;
        const zone = await zoneResp.json();
        if (!zoneResp.ok) {
          setZone(zone.message);
          return;
        }  
        
        setZone(children(zone, audits, getMoreAudits));
      } catch(e) {
        console.error(e);
        setZone("failed");
      }
    }

    fetchZone(zoneId);
  }, [zoneId, children]);

  return zone;
}
