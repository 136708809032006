import PropTypes from 'prop-types';
import { useState } from "react";
import Audit from "./Audit";
import InfiniteScroll from "react-infinite-scroll-component";

export default function AuditList({startingAudits, getMoreAudits}) {
  const [audits, setAudits] = useState(
    startingAudits.audits?.audits !== undefined ? startingAudits.audits?.audits : []
  );
  const [hasMore, setHasMore] = useState(startingAudits.audits?.hasMore === true);
  const [error, setError] = useState(
    (startingAudits.statusCode !== 200) ? startingAudits.message :
      (startingAudits.audits.audits.length === 0) ? "There are currently no audits" : ""
  );
  const [key, setKey] = useState(startingAudits.audits?.key || "");

  function moreAudits() {
    const moreAudits = getMoreAudits(key);
    if (moreAudits.statusCode !== 200) {
      setError(moreAudits.message);
      setHasMore(false);
      return;
    }
    setAudits([
      ...audits,
      ...moreAudits.audits.audits,
    ]);
    setHasMore(moreAudits.audits?.hasMore === true);
    setKey(moreAudits.audits?.key || "");
  }

  return (
    <>
      <InfiniteScroll dataLength={audits.length} next={moreAudits} hasMore={hasMore} loader="Loading...">
        <table className="SectionHeader" height="100%" width="100%">
          <thead>
            <tr className="HeaderRow">
              <td>Log Date</td>
              <td>Action</td>
              <td>Object</td>
              <td>Who</td>
              <td>Before</td>
              <td>After</td>
            </tr>
          </thead>
          <tbody>
            {
              audits.map((audit, i) => <Audit i={i} audit={audit} key={audit.auditId} />)
            }          
          </tbody>
        </table>
      </InfiniteScroll>
      <div>{error}</div>
    </>
  );
}

AuditList.propTypes ={
  startingAudits: PropTypes.object.isRequired,
  getMoreAudits: PropTypes.func.isRequired,
};

AuditList.defaultProps = {
};
