import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

export default function ZoneLoader({zoneAndRecords, children}) {
  const [zone, setZone] = useState("Loading...");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const zoneId = query.get("id");

  useEffect(() => {
      async function fetchZone(zoneId) {
          try {
              const results = await fetch(`${window.location.origin}/api/zone/${zoneId}`, {
                  headers: {
                      "X-APIKEY": localStorage.SessionToken,
                  }
              });
              const zone = await results.json();
              if (!results.ok) {
                  console.error(zone);
                  console.error(results);
                  setZone(zone.message);
                  return;
              }
              setZone(children(zone));
          } catch(e) {
              console.error(e);
              setZone("failed");
          }
      }

      if (zoneAndRecords) {
        setZone(children(zoneAndRecords));
      } else {
        fetchZone(zoneId);
      }
  }, [zoneId, zoneAndRecords, children]);

  return zone;
}
