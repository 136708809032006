import PropTypes from 'prop-types';
import React, { useState } from "react";
import TTL from "./TTL";

export default function AddRecordForm({zone, defaultRecordType, setMessage}) {
  const types = [
    {
      text: "MX(Mail Exchange)",
      valueTag: "Mailserver Hostname",
      preferenceTag: "MX Distance",
      value: "MX",
    },
    {
      text: "NS(Nameserver)",
      valueTag: "Nameserver Hostname",
      value: "NS",
    },
    {
      text: "TXT(Text Record)",
      valueTag: "Text",
      value: "TXT",
    },
    {
      text: "A(Address)",
      valueTag: "Address",
      value: "A",
    },
    {
      text: "PTR(Name Pointer)",
      valueTag: "Hostname",
      value: "PTR"
    },
    {
      text: "CNAME(Canonical Name)",
      valueTag: "Hostname",
      value: "CNAME",
    },
    {
      text: "AAAA(IPv6 Address)",
      valueTag: "IPv6 Address",
      value: "AAAA",
    },
    {
      text: "SRV(Service Record)",
      valueTag: "Service Record",
      value: "SRV",
    },
  ];

  const defaultType = types.filter((type) => {
    return type.value === defaultRecordType;
  });

  const defaultPreference = defaultType[0].preferenceTag;
  const [recordData, setRecordData] = useState({
    type: defaultRecordType,
    value: "",
    hostname: "",
    preference: "",
    ttl: "",
  });
  const [preferenceTag, setPreferenceTag] = useState({
    text: defaultPreference || "",
    disabled: defaultPreference === undefined,
  });
  const [valueTag, setValueTag] = useState(defaultType[0].valueTag);
  const defaultSubmitButton = <input type="submit" value="Add new record" className="Save" />;
  const [submitButton, setSubmitButton] = useState(defaultSubmitButton);

  async function createRecord() {
    try {
      const newRecordData = {
        Type: recordData.type,
        Value: recordData.value,
        HostName: recordData.hostname,
        Preference: recordData.preference === "" ? undefined : recordData.preference,
        Ttl: recordData.ttl || zone.ttl
      }
      const results = await fetch(`${window.location.origin}/api/zone/${zone.zoneId}/record`, {
        headers: {
          "X-APIKEY": localStorage.SessionToken,
        },
        method: "POST",
        body: JSON.stringify(newRecordData),
      });
      const resp = await results.json();
      if (!results.ok) {
        setMessage(resp.message);
        return;
      }
      setMessage("Record Added Successfully. Please refresh page to view changes.");
    } catch (e) {
      setMessage(`${e.toString()}`);
    } finally {
      setSubmitButton(defaultSubmitButton);
    }
  }

  function handleSubmit(event) {
    setSubmitButton('Creating...');
    createRecord().then(() => null);
    event.preventDefault();
  }

  function handleChange(field) {
    return (event) => {
      setRecordData({
        ...recordData,
        [field]: event.target.value,
      });
    };
  }

  function DNSRecordTypeIDChange(event) {
    const type = types[event.target.selectedIndex];
    setValueTag(type.valueTag);
    if (type === undefined || type.preferenceTag === undefined) {
      setRecordData({
        ...recordData,
        preference: "",
        type: event.target.value,
      });
      setPreferenceTag({
        text: "",
        disabled: 1,
      });
      return;
    }

    setRecordData({
      ...recordData,
      type: event.target.value,
    });
    setPreferenceTag({
      text: type.preferenceTag,
      disabled: 0,
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="100%">
        <tbody>
          <tr>
            <td width="20%">Hostname</td>
            <td width="80%"><input name="hostname" className="textBoxFitted" value={recordData.hostname} onChange={handleChange("hostname")} />.{zone.fqdn}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>
              <select name="DNSRecordTypeID" onChange={DNSRecordTypeIDChange} value={recordData.type}>
                {types.map((type) => {
                  return (<option value={type.value} key={type.value}>{type.text}</option>)
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td id="ValueTag">{valueTag}</td>
            <td><input name="Value" className="textBox" value={recordData.value} onChange={handleChange("value")} /></td>
          </tr>
          <tr>
            <td id="preferenceTag">{preferenceTag.text}</td>
            <td><input name="preference" className="textBox" disabled={preferenceTag.disabled} value={recordData.preference} onChange={handleChange("preference")} /></td>
          </tr>
          <tr>
            <td id="TTLTag">TTL</td>
            <td>
              <TTL value={recordData.ttl} onChange={handleChange("ttl")} />
            </td>
          </tr>
        </tbody>
      </table>
      {submitButton}
    </form>
  );
}

AddRecordForm.propTypes = {
  defaultRecordType: PropTypes.string,
  zone: PropTypes.object.isRequired,
};

AddRecordForm.defaultProps = {
  defaultRecordType: "A",
};
