import DomainsGif from "../../assets/Domains.gif";
import Actions from "./Actions";
import TableRows from "./TableRows";
import PropTypes from "prop-types";
import TablePagination from "./TablePagination";
import {Link} from "react-router-dom";

export function DomainsTable({zones, count, queryOwner, querySearch, queryCustomerId, queryOffset, queryRowcount,
                               homeurl}) {
  return (
    <>
      <table className="SectionTable" width="100%">
        <thead>
        <tr>
          <td className="SectionHeader">
            <img src={DomainsGif} alt="icon" className="domains-gif"/>
            Domains
          </td>
        </tr>
        </thead>
        <td className="SectionBody">
          <table style={{ width: '100%' }}>
            <tbody>
            <tr className="HeaderRow">
              <td>Domain Name</td>
              <th>Actions</th>
            </tr>
            <TableRows data={prepareRows({zones, homeurl})} alternate={true}/>
            </tbody>
          </table>
          <table style={{ width: '100%' }}>
            <tbody>
            <TablePagination
              count={count}
              queryOwner={queryOwner}
              querySearch={querySearch}
              queryCustomerId={queryCustomerId}
              queryOffset={queryOffset}
              queryRowcount={queryRowcount}/>
            </tbody>
          </table>
        </td>
      </table>
    </>
  );
}

DomainsTable.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number,
  queryOwner: PropTypes.string,
  querySearch: PropTypes.string,
  queryCustomerId: PropTypes.string,
  queryOffset: PropTypes.number,
  queryRowcount: PropTypes.number,
  homeurl: PropTypes.string,
}

DomainsTable.defaultProps = {
}

function prepareRows({zones, homeurl}) {
  const preparedZones = []
  zones.forEach((vdnsZone) => {
    let zoneData = {
      fqdn: (<Link to={`${homeurl}vdns/edit.html?id=${vdnsZone.zoneId}`}>
        {vdnsZone.fqdn}
      </Link>),
      actions: <Actions zoneId={vdnsZone.zoneId}/>
    }
    preparedZones.push(zoneData)
  })
  return preparedZones
}
