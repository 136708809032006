import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Saml from './app/Saml';

function homeurl() {
  return "/";
}

function isLoggedIn() {
  if (localStorage.loggedIn !== "true") {
    return false;
  }
  const start = parseInt(localStorage.start, 10);
  const expiring = start + parseInt(localStorage.expiresIn, 10) * 1000;
  return expiring > Date.now();
}

if (!isLoggedIn() && process.env.NODE_ENV !== "development") {
  ReactDOM.render(
    <React.StrictMode>
      <Saml homeurl={homeurl()} />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App homeurl={homeurl()} />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
