import React, { useState } from "react";
import PropTypes from 'prop-types';
import RecordForm from "./RecordForm";

export default function EditDomainForm({zoneAndRecords, setMessage}) {
  const fixRecords = fixPtrs(zoneAndRecords.records);
  const [records, setRecords] = useState(fixRecords);
  const originalRecords = fixRecords;
  const defaultSubmitValue = "Save Changes";
  const [submitValue, setSubmitButton] = useState(defaultSubmitValue);

  function fixPtrs (records) {
    records.forEach((record) => {
      // fixes ptr records to display correctly
      if (record.type === "PTR") {
        const fqdn = record.fqdn;
        record.fqdn = record.destination.split(".").pop()
        record.destination = fqdn;
      }
    });
    return records;
  }

  async function editRecords() {
    try {
      const recordsToUpdate = records.filter((record) => { return record.changed === true });
      const recordsToDelete = records.filter((record) => { return record.delete === true });
      const deleteIds = [];
      recordsToDelete.forEach((r) => { deleteIds.push({ type: r.type, deleteId: r.recordId }) });
      const editData = {
        update: recordsToUpdate,
        delete: deleteIds
      }
      if (recordsToUpdate.length === 0 && deleteIds.length === 0) {
        setMessage("There are no changes to save.\nPlease ensure you have made changes before clicking Save Changes.")
        setSubmitButton(defaultSubmitValue);
      } else {
        const results = await fetch(`${window.location.origin}/api/zone/${zoneAndRecords.zone.zoneId}/records`,{
          headers: {
            "X-APIKEY": localStorage.SessionToken,
          },
          method: "PUT",
          body: JSON.stringify(editData)
        });
        if (!results.ok) {
          const resp = await results.json();
          setMessage(resp.message);
          return;
        }
        setMessage(`${deleteIds.length} record(s) deleted, ${recordsToUpdate.length} record(s) updated.`+
          ` Please refresh page to view changes.`)
      }
    } catch (e) {
      setMessage(`${e.toString()}`);
    } finally {
      setSubmitButton(defaultSubmitValue);
    }
  }

  function handleSubmit(event) {
    setSubmitButton(`Saving Changes...`);
    editRecords().then(() => null);
    event.preventDefault();
  }

  function setRecord(i) {
    return (record) => {
      const newRecords = [...records];
      newRecords[i] = record;
      setRecords(newRecords);
    };
  }

  return (
    <table className="SectionTable" width="100%"><tbody>
      <tr>
        <td className="SectionHeader" align="left">Domain Records</td>
      </tr>
      <tr>
        <td className="SectionBody">
          <form onSubmit={handleSubmit}>
            <table width="100%">
              <tbody>
                <tr className="HeaderRow">
                  <th width="35%" nowrap="">Hostname</th>
                  <th width="5%">Type</th>
                  <th width="10%">MX Distance</th>
                  <th width="40%">Value</th>
                  <th width="5%">TTL</th>
                  <th width="5%">Delete</th>
                </tr>
                {records.map((record, i) => <RecordForm record={record} zone={zoneAndRecords.zone}
                                                        key={record.recordId} setRecord={setRecord(i)} i={i}
                                                        originalRecords={originalRecords} setMessage={setMessage}/>
                                                        )}
              </tbody>
            </table>
            <input type="submit" value={submitValue} className="Save" />
          </form>
        </td>
      </tr>
    </tbody></table>
  );
}

EditDomainForm.propTypes = {
  zoneAndRecords: PropTypes.object.isRequired,
};

EditDomainForm.defaultProps = { };
