import '../index.css';
import PropTypes from 'prop-types';
import DataPipeLogo from "../assets/datapipeLogo.jpeg";
import RackspaceLogo from "../assets/rs-logo-2021B.svg";
import React, {useState} from "react";

export default function Header({ logo, impersonateState, errorState }) {
  const isImpersonated = JSON.parse(localStorage.getItem("isImpersonated")) || false;
  const defaultSubmitValue =  isImpersonated ? "end impersonation" : "impersonate";
  const [userInput, setUserInput] = useState( "");
  const [userImpersonate, setImpersonate] = useState(localStorage.user || impersonateState);
  const [error, setError] = useState(errorState);
  const [submitValue, setSubmitButton] = useState(defaultSubmitValue);


  async function impersonate() {
    try {
      if (isImpersonated) {
        // switch back to original session
        const originalSession = JSON.parse(localStorage.originalSession);
        localStorage.setItem("SessionToken", originalSession.SessionToken);
        localStorage.setItem("expiresIn", originalSession.expiresIn);
        localStorage.setItem("start", originalSession.start);
        localStorage.setItem("isImpersonated", JSON.stringify(false));
        localStorage.removeItem("user");
        localStorage.removeItem("originalSession");
        window.location.replace(window.location.origin);
      } else if (userInput === ""){
        setSubmitButton(defaultSubmitValue);
      } else {
        const request = {
          username: userInput
        }
        const results = await fetch(`${window.location.origin}/api/impersonate`, {
          headers: {
            "X-APIKEY": localStorage.SessionToken,
          },
          method: "POST",
          body: JSON.stringify(request)
        });
        if (!results.ok) {
          const resp = await results.json();
          if (resp.message === "Unauthorized") {
            setError(resp.message + ": Only Rackspace Employees are authorized to use impersonation")
          } else {
            setError(resp.message);
          }
          setSubmitButton(defaultSubmitValue);
          return;
        }
        // switch to impersonation session
        const response = await results.json()
        localStorage.setItem("originalSession", JSON.stringify(localStorage));
        localStorage.setItem("SessionToken", response.SessionToken);
        localStorage.setItem("expiresIn", response.expiresIn);
        localStorage.setItem("user", userInput);
        localStorage.setItem("start", JSON.stringify(Date.now()));
        localStorage.setItem("isImpersonated", JSON.stringify(true));
        window.location.replace(window.location.origin);
      }
    } catch (e) {
      setError(`${e.toString()}`);
    }
  }

  function handleChange(setState) {
    return (event) => {
      setState(event.target.value);
    };
  }

  function handleClick(event) {
    setImpersonate("");
    setError("");
    event.preventDefault();
  }

  function handleSubmit(event) {
    setSubmitButton(`impersonating...`);
    impersonate().then(() => null);
    event.preventDefault();
  }

  function Impersonated({userImpersonate, error}) {
    if (userImpersonate !== "" && typeof(userImpersonate) === "string") {
      return (<p className="Impersonate">Impersonating {userImpersonate}</p>);
    } else if (error !== "" && typeof(error) === "string") {
      return (<p className="Impersonate-Error" title="Click to clear error" onClick={handleClick}>{error}</p>)
    }
    return <p className="Impersonate">{null}</p>;
  }

  if ( logo === 'HEADER_RACKSPACE') {
    return (
      <header className="App-header">
        <form onSubmit={handleSubmit} className="Impersonate-Form">
          <img src={RackspaceLogo} className="App-logo" alt="logo" />
          <Impersonated userImpersonate={userImpersonate} error={error}/>
          <input value={userInput} className="Impersonate-Input" onChange={handleChange(setUserInput)}/>
          <input type="submit" value={submitValue} className="Impersonate-Button" />
        </form>
      </header>
    )
  }
  return (
    <header className="App-header">
      <img src={DataPipeLogo} className="App-logo" alt="logo" />
    </header>
  );
}


Header.propTypes = {
  logo: PropTypes.string.isRequired,
  impersonateState: PropTypes.string,
  errorState: PropTypes.string
}

Header.defaultProps = {
}
