import PropTypes from 'prop-types';
import React, { useState } from "react";

export default function EditZoneForm({zone, setMessage}) {
  const [ttl, setTTL] = useState(zone.ttl);
  const [comment, setComment] = useState(zone.comment);
  const defaultSubmitValue = "Update Domain";
  const [submitValue, setSubmitButton] = useState(defaultSubmitValue);

  function handleChange(setState) {
    return (event) => {
      setState(event.target.value);
    };
  }

  function handleSubmit(event) {
    setSubmitButton("Updating Domain...");
    updateZone().then(() => null);
    event.preventDefault();
  }

  async function updateZone() {
    try {
      const updateDomain = {
        ttl,
        comment
      }
      if (ttl === zone.ttl && comment === zone.comment) {
        setMessage("There are no updates to save.\nPlease ensure you have made changes before clicking Update Domain.");
        setSubmitButton(defaultSubmitValue);
      } else {
        const results = await fetch(`${window.location.origin}/api/zone/${zone.zoneId}`, {
          headers: {
            "X-APIKEY": localStorage.SessionToken,
          },
          method: "PATCH",
          body: JSON.stringify(updateDomain)
        });
        if (!results.ok) {
          const resp = await results.json();
          setMessage(resp.message);
          return;
        }
        setMessage(`Domain updated. Please refresh page to view changes.`)
      }
    } catch (e) {
      setMessage(`${e.toString()}`);
    } finally {
      setSubmitButton(defaultSubmitValue);
    }
  }

  return (
    <table className="SectionTable" width="100%">
      <tbody><tr>
        <td className="SectionHeader" align="left">
          Domain Information
        </td>
      </tr>
      <tr id="element-1626.06045304275">
        <td className="SectionBody">
          <form onSubmit={handleSubmit}>
          <table width="100%">
            <tbody><tr className="HeaderRow">
              <td width="20%">Name</td>
              <td width="80%">Value</td>
            </tr>
            <tr className="Row">
              <th align="right">Domain Name</th>
              <td>{zone.fqdn}</td>
            </tr>
            <tr className="AlternateRow">
              <th align="right">Domain ID</th>
              <td>{zone.zoneId}</td>
            </tr>
            <tr className="Row">
              <th align="right">serial</th>
              <td>{zone.serialNumber}</td>
            </tr>
            <tr className="AlternateRow">
              <th align="right">refresh</th>
              <td>{zone.refresh}</td>
            </tr>
            <tr className="Row">
              <th align="right">retry</th>
              <td>{zone.updateRetry}</td>
            </tr>
            <tr className="AlternateRow">
              <th align="right">expire</th>
              <td>{zone.expiry}</td>
            </tr>
            <tr className="Row">
              <th align="right">minimum</th>
              <td>{zone.minimumTtl}</td>
            </tr>
            <tr className="AlternateRow">
              <th align="right">ttl</th>
              <td><input value={ttl} onChange={handleChange(setTTL)} className="textBox" /></td>
            </tr>
            <tr className="Row">
              <th align="right">comment</th>
              <td><input value={comment} onChange={handleChange(setComment)} className="textBox" /></td>
            </tr>
            <tr className="AlternateRow">
              <th align="right">Customer ID</th>
              <td>{zone.tenantId}</td>
            </tr></tbody>
          </table>
          <input type="submit" value={submitValue} />
          </form>
        </td>
      </tr></tbody>
    </table>
  );
}

EditZoneForm.propTypes ={
  zone: PropTypes.object.isRequired,
}

EditZoneForm.defaultProps = {
}
