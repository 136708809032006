import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function Authtkt(props) {
  const [loginMessage, setLoginMessage] = useState("Getting authentication ticket");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function fetchTicket() {
      const back = searchParams.get("back");
      if (!back.startsWith("https://secure.datapipe.net/smb")) {
        setLoginMessage("invalid URL passed in");
        return;
      }
      try {
        const results = await fetch(`${window.location.origin}${props.homeurl}api/authtkt`, {
          method: "POST",
          cache: "no-cache",
          headers: {
            "X-APIKEY": localStorage.SessionToken,
          },
        });
        const ticket = await results.json();
        if (!results.ok) {
          if (ticket.message !== undefined) {
            setLoginMessage(ticket.message);
          } else {
            setLoginMessage("HTTP error getting auth token");
          }
          return;
        }
        if (ticket.tkt.length < 1) {
          setLoginMessage("invalid ticket returned from service");
          return;
        }
        const url = new URL(back);
        url.searchParams.set("auth_tkt", ticket.tkt);
        window.location.replace(url.toString());
      } catch(e) {
        console.error(e);
        setLoginMessage("internal error when getting auth ticket - try reloading");
      }
    }

    fetchTicket();
  }, [props.homeurl, searchParams]);

  return (
    <>
      <main>
        <div id="layout-content" className="layout-content-wrapper">
          {loginMessage}
        </div>
      </main>
    </>
  );
}
