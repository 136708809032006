import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useState } from "react";
import Messages from '../Messages';
import NavHeader from '../NavHeader';
import Banner from "../Banner";

export default function Delete({zoneAndRecords, defaultMessage, defaultZoneDeleted}) {
  const [message, setMessage] = useState(defaultMessage);
  const [zoneDeleted, setZoneDeleted] = useState(defaultZoneDeleted || false);

  async function deleteZone() {
    try {
      const results = await fetch(`${window.location.origin}/api/zone/${zoneAndRecords.zone.zoneId}`, {
          headers: {
              "X-APIKEY": localStorage.SessionToken,
          },
          method: "DELETE"
      });
      const resp = await results.json();
      if (!results.ok) {
          console.error(results);
          setMessage(resp.message);
          return;
      }
      // TODO: redirect to zone list with message
      setMessage("zone deleted");
      setZoneDeleted(true);
    } catch(e) {
      console.error(e);
      setMessage("failed");
    }
  }

  function onSubmit(event) {
    deleteZone();
    event.preventDefault();
  }

  if (zoneDeleted) {
    return (
      <div>
      <NavHeader currentPage="/vdns/delete.html"></NavHeader>
      <Banner>Delete a Domain</Banner>
      <div className="mainbody">
        {message ? <Messages message={message} ></Messages> : ""}
      </div>
      </div>
    );
  }

  return (
    <div>
      <NavHeader currentPage="/vdns/delete.html" zoneAndRecords={zoneAndRecords}></NavHeader>
      <Banner>Delete a Domain</Banner>
      <div className="mainbody">
        {message ? <Messages message={message} ></Messages> : ""}
        Are you sure you want to delete {zoneAndRecords.zone.fqdn}?
        <table>
          <tbody><tr>
            <td>
              <form onSubmit={onSubmit}>
              <input type="submit" value="Yes" />
              </form>
            </td><td>
              <Link role="button" to={`/vdns/edit.html?id=${zoneAndRecords.zone.zoneId}`} className="buttonLink">No</Link>
            </td>
          </tr></tbody>
        </table>
      </div>
    </div>
  );
}

Delete.propTypes = {
  zoneAndRecords: PropTypes.object.isRequired,
  defaultMessage: PropTypes.string,
  defaultZoneDeleted: PropTypes.bool,
};

Delete.defaultProps = {
};
