import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export default function Actions({className, zoneId}) {
  return (
    <>
      <Link className={className} to={`/vdns/edit.html?id=${zoneId}`}>Edit </Link>
      <Link className={className} to={`/vdns/delete.html?id=${zoneId}`}>Delete </Link>
      <Link className={className} to={`/vdns/log.html?id=${zoneId}`}>View Log</Link>
    </>
  )
}

Actions.propTypes = {
  className: PropTypes.string,
  zoneId: PropTypes.string.isRequired
}

Actions.defaultProps = {
  className: null
}
