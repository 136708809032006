import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function NavLink({to, children, currentPage}) {
  const onPage = to.startsWith(currentPage);
  return (
    <>
      {onPage ? "[" : ""}<Link to={to}>{children}</Link>{onPage ? "]" : ""}
    </>
  );
}

function ZoneLinks({zoneId, zoneAndRecords, currentPage}) {
  if (zoneAndRecords === undefined && (zoneId === "" || zoneId === undefined)) {
    return null;
  }

  const id = zoneId || zoneAndRecords.zone.zoneId;

  return (
    <>
      <div className="subMenuLeftCell">
        <NavLink to={`/vdns/edit.html?id=${id}`} currentPage={currentPage}>Edit Domain</NavLink>
      </div>
      <div className="subMenuLeftCell">
        <NavLink to={`/vdns/delete.html?id=${id}`} currentPage={currentPage}>Delete Domain</NavLink>
      </div>
      <div className="subMenuLeftCell">
        <NavLink to={`/vdns/log.html?id=${id}`} currentPage={currentPage}>Domain Logs</NavLink>
      </div>
    </>
  );
}

export default function NavHeader({zoneId, zoneAndRecords, currentPage}) {
  return (
    <div className="subMenuTable">
      <div className="subMenuLeftCell">
        <NavLink to={"/vdns/index.html"} currentPage={currentPage}>List Domains</NavLink>
      </div>
      <div className="subMenuLeftCell">
        <NavLink to={"/vdns/add.html"} currentPage={currentPage}>Add Domain</NavLink>
      </div>
      <ZoneLinks zoneId={zoneId} zoneAndRecords={zoneAndRecords} currentPage={currentPage}></ZoneLinks>
    </div>
  );
}

NavHeader.propTypes = {
  zoneId: PropTypes.string,
  zoneAndRecords: PropTypes.object,
  currentPage: PropTypes.string.isRequired,
};

NavHeader.defaultProps = {
};
